<template>
    <Mint />
</template>

<script>
// @ is an alias to /src
import Mint from '@/components/Mint.vue'

export default {
  name: 'Home',
  components: {
    Mint
  }
}
</script>
