<template>
  <div id="app">
    <div id="main-wrapper" class="push_container">
      <header id="main-header" class="bg-white">
        <div id="pre-header" class="bg-petrol">
          <div class="container">
            <div class="row variable-gutters">
              <div class="col-6">
                <strong class="text-white">Piattaforma di creazione NFT</strong>
              </div><!-- /col-6 -->
              <div class="col-6 header-utils-wrapper">
                <div class="header-utils">
                  <a href="https://www.liceofarnesina.edu.it" target="_blank">
                    <span class="mr-2">Vai al sito ufficiale del Liceo Farnesina</span>
                  </a>
                </div><!-- /header-utils -->
              </div><!-- /col-6 -->
            </div><!-- /row -->
          </div><!-- /container -->
        </div>
        <div class="container header-top">
          <div class="row variable-gutters">
            <div class="col-8 d-flex align-items-center">
              <button class="hamburger hamburger--spin-r toggle-menu menu-left push-body d-xl-none" type="button"
                aria-label="apri chiudi navigazione">
                <span class="hamburger-box">
                  <span class="hamburger-inner"></span>
                </span>
              </button>
              <!-- Left menu element-->
              <div class="cbp-spmenu cbp-spmenu-vertical cbp-spmenu-left perfect-scrollbar">
                <div class="logo-header">
                  <svg width="100%" height="100%" class="icon" aria-hidden="true"><image href="/assets/img/logo_rep_ita.svg" xlink:href="/assets/img/logo_rep_ita.svg" width="100%" height="100%"></image></svg>
  
                  <p class="h1">
                    <span>Liceo Scientifico-Musicale Statale</span>
                    <span><strong>Farnesina</strong></span>
                    <span>Roma</span>
                  </p>
                </div><!-- /logo-header -->
                <div class="nav-list-mobile dl-menuwrapper">
                  <nav aria-label="Principale">
                    <ul class="dl-menu nav-list nav-list-primary" data-element="menu">
                      <li class="text-redbrown">
                        <a href="/">Home</a>
                      </li>
                      <li class="text-greendark">
                        <router-link :to="'/nfts/' + account" class="nav-link">I tuoi NFT</router-link>
                      </li>
                      <li class="text-purplelight">
                        <router-link :to="'/galleria/'" class="nav-link d-none">Galleria pubblica</router-link>
                      </li>
                      <li class="text-bluelectric d-none">
                        <a>Link 4</a>
                      </li>
                    </ul><!-- /nav-list -->
                  </nav>
                </div>
              </div>
              <!-- End Left menu element-->
              <div class="logo-header">
                <a href="/" class="d-inline-flex" aria-label="Liceo Farnesina - Creatore NFT"
                  title="vai alla home page">
                  <svg width="100%" height="100%" class="icon" aria-hidden="true"><image href="https://www.liceofarnesina.edu.it/wp-content/uploads/2023/09/logo_rep_ita.svg" xlink:href="https://www.liceofarnesina.edu.it/wp-content/uploads/2023/09/logo_rep_ita.svg" width="100%" height="100%"></image></svg>
                  <div class="h1">
                    <span>Liceo Scientifico-Musicale Statale</span>
                    <span><strong>Farnesina</strong></span>
                    <span>Roma</span>
                  </div>
                </a>
              </div><!-- /logo-header -->
              <div class="sticky-main-nav">
  
              </div><!-- /sticky-main-nav -->
            </div><!-- /col -->
            <div class="col-4 d-flex align-items-center justify-content-end">

            </div><!-- /col -->
          </div><!-- /row -->
        </div><!-- /container -->
        <div class="bg-white d-none d-xl-block header-bottom" id="sub-nav">
          <div class="container">
            <div class="row variable-gutters">
              <div class="col nav-container">
                <nav aria-label="Principale" class="main-nav" id="menu-principale">
                  <ul class="dl-menu nav-list nav-list-primary" data-element="menu">
                    <li class="text-redbrown">
                      <a class="nav-link" href="/">
                        Home
                      </a>
                    </li>
                    <li v-if="account" class="text-greendark">
                      <router-link :to="'/nfts/' + account" class="nav-link">I tuoi NFT</router-link>
                    </li>
                    <li class="text-purplelight">
                      <router-link :to="'/galleria/'" class="nav-link d-none">Galleria pubblica</router-link>
                    </li>
                    <li class="text-bluelectric d-none">
                      <a class="nav-link">Link 4</a>
                    </li>
                  </ul><!-- /nav-list -->
                </nav>
              </div><!-- /col nav-container -->
            </div><!-- /row -->
          </div><!-- /container -->
        </div><!-- /sub-nav -->
      </header><!-- /header -->

      <router-view />

      <footer id="footer-wrapper" class="footer-wrapper">
        <div class="container">
          <div class="row variable-gutters mb-5">
            <div class="col logos-wrapper">
              <img class="ue-logo" src="https://www.liceofarnesina.edu.it/wp-content/themes/design-scuole-wordpress-theme-main/assets/img/logo-eu-inverted.svg" alt="Finanziato dall' Unione Europea - Next generation EU">
              <div class="logo-footer">
                <a href="https://www.liceofarnesina.edu.it" aria-label="Vai alla homepage" title="Vai alla homepage">
                  <svg width="100%" height="100%" class="icon" aria-hidden="true"><image href="/assets/svg/logo-repubblica-italiana.svg" xlink:href="/assets/img/logo-repubblica-italiana.svg" width="100%" height="100%" style="filter: invert(1)"></image></svg>
                </a>
                <h2 class="h1">
                  <a href="https://www.liceofarnesina.edu.it">
                    <span>Liceo Scientifico-Musicale Statale</span>
                    <span><strong>Farnesina</strong></span>
                    <span>Roma</span>
                  </a>
                </h2>
              </div>
            </div>
          </div>
          <div class="row variable-gutters">
            <div class="col-lg-12 sub-footer">
              <ul id="menu-footer" class="footer-inline-menu">
                <li><a class="text-underline-hover" href="https://www.liceofarnesina.edu.it/whistleblowing/">WHISTLEBLOWING</a></li>
                <li><a class="text-underline-hover" href="https://www.liceofarnesina.edu.it/modello-patto-di-integrita/">Modello patto di integrit&agrave;</a></li>
                <li><a class="text-underline-hover" href="https://form.agid.gov.it/view/a0fe7c47-6401-4c67-b3ac-a8471342384b" data-element="accessibility-link">Dichiarazione di Accessibilit&agrave;</a></li>
                <li><a class="text-underline-hover" href="https://www.liceofarnesina.edu.it/accessibilita-e-meccanismo-di-feedback/" data-element="accessibility-link">Accessibilit&agrave; e meccanismo di feedback</a></li>
                <li><a class="text-underline-hover" href="https://www.liceofarnesina.edu.it/note-legali/" data-element="legal-notes">Note legali</a></li>
                <li><a class="text-underline-hover" href="https://www.liceofarnesina.edu.it/privacy-policy/" data-element="privacy-policy-link">Privacy Policy</a></li>
              </ul>
            </div>
          </div>
          <div class="row variable-gutters mb-3">
            <div class="col-lg-12 text-left text-md-center footer-text">
              <div class="row">
                <div class="col-12 col-lg-4">
                  <b>Sede Centrale:</b><br>
                  Via dei Giuochi Istmici, 64<br>
                  00135 ROMA<br><a href="tel.+3906121127045">tel.+3906121127045</a><br>
                  Fax 0636309457
                </div>
                <div class="col-12 col-lg-4">
                  <b>Sede Robilant</b><br>
                  Via dei Robilant, 7 e 2<br>
                  00135 ROMA<br><a href="tel.+390633221715">tel.+390633221715</a><br><a>tel.+3906121124645</a>
                </div>
                <div class="col-12 col-lg-4">
                  <b>Sede Gosio</b><br>
                  Via Gosio, 90<br>
                  e Via Serra, 91<br>
                  00191 ROMA<br><a>tel.+3906121124705</a>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-12">
                  Distretto Scolastico 28&deg; | Cod. Fisc. 05723890587 | <b>Email</b> rmps49000c@istruzione.it | <b>PEC</b> rmps49000c@pec.istruzione.it
                </div>
              </div>
            </div>
          </div>
          <div class="row variable-gutters mb-3">
            <div class="col-lg-10 offset-lg-1 text-right footer-link small">
                <a href="https://designers.italia.it/kit/scuole/" aria-label="Concept &amp; Design by Designers Italia" target="_blank">Concept &amp; Design by Designers Italia</a>
                 - 
                <a href="https://creditcarbon.io" aria-label="NFT Platform Developed by CarbonLab" target="_blank">NFT Platform Developed by CarbonLab</a>
                 - 
                 <a href="https://quadrans.io" aria-label="Powered by Quadrans Blockchain" target="_blank">Powered by Quadrans Blockchain</a>
            </div>
          </div>
        </div>
      </footer>

    </div><!-- /push_container -->

  </div>
</template>

<script>
var Web3 = require("web3");

export default {
  name: "App",
  data() {
    return {
      account: "",
      web3: new Web3(window.ethereum),
    }
  },
  mounted() {
    this.connect()
  },
  methods: {
    async connect() {
      const app = this;
      if (window.ethereum !== undefined) {
        console.log("Connecting..")
        try {
          window.ethereum.enable();
          window.ethereum.on("accountsChanged", (accounts) => {
            app.connect();
          });
          let accounts = await app.web3.eth.getAccounts();
          app.account = accounts[0];
        } catch (e) {
          console.log("Error while connecting", e)
        }
      }
    },
  }
};
</script>
<style>
.upload {
    width: auto;
    height: auto;
    opacity: unset;
    z-index: auto;
}
</style>