import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Nfts from '../views/Nfts.vue'
import Nft from '../views/Nft.vue'
import Profile from '../views/Profile.vue'
import Gallery from '../views/Gallery.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/nfts/:address',
    name: 'Nfts',
    component: Nfts
  },
  {
    path: '/nfts/:address/:tokenId',
    name: 'Nft',
    component: Nft
  },
  {
    path: '/nfts/:contract/:address/:tokenId',
    name: 'Nft',
    component: Nft
  },
  {
    path: '/vetrina/:address',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/galleria/',
    name: 'Gallery',
    component: Gallery
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
