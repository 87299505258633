<template>
  <div>
    <section class="section bg-purplelight bg-purplelightgradient py-5 position-relative d-flex align-items-center overflow-hidden">
      <div class="container">
        <div class="row variable-gutters">
          <div class="col">
            <div class="hero-title text-left">
              <h1 class="p-0 mb-2">Galleria pubblica</h1>
              <p class="h5 font-weight-normal">Tutti gli NFT creati con gli Smart Contract del Liceo Scientifico-Musicale Statale Farnesina </p>
            </div><!-- /hero-title -->
          </div><!-- /col-md-5 -->
        </div><!-- /row -->
      </div><!-- /container -->
    </section>
    <div class="container">
      <section>
        <div class="columns is-mobile mt-5">
          <div class="
              column
            ">

            <div v-if="isLoading" style="padding: 25vh 0; text-align: center">
              Caricamento dei dati dalla blockchain..
            </div>
            <div v-if="errored" style="padding: 25vh 0; text-align: center">
              Mi spiace, non è possibile caricare i dati in questo momento..
            </div>
            <div v-if="errored && !isLoading && nfts.length === 0" style="padding: 35vh 0; text-align: center">
              Mi spiace, non sono presenti NFT in questo wallet..
            </div>
            <div v-if="!isLoading && nfts.length > 0">
              <div v-for="nft in nfts" v-bind:key="nft.tokenId" class="columns is-centered">
                <div class="column is-two-thirds">
                  <article class="card card-bg card-article card-article-redbrown">
                    <div class="card-body">
                      <div class="card-article-img">
                        <a :href="
                          '/nfts/' + nft.contract + ':' + nft.standard + '/' + $route.params.address + '/' + nft.tokenId
                        ">
                          <img :src="
                            nft.image.replace('ipfs://', API_URL + '/ipfs/')
                          " style="max-height: 500px !important; width: 100%" />
                        </a>                    </div>
                      <div class="card-article-content">
                        <h2 class="h3"><a :href="
                              '/nfts/' + nft.contract + ':' + nft.standard + '/' + $route.params.address + '/' + nft.tokenId
                            ">{{ nft.name }}</a></h2>
                        <p>{{ nft.description }}</p>
                        <p><strong>NFT Standard:</strong> QDC-{{ nft.standard }}</p>
                      </div><!-- /card-avatar-content -->
                    </div><!-- /card-body -->
                  </article><!-- /card card-bg card-article -->
                </div>
              </div>
            </div>
            <div v-if="!isLoading && nfts.length === 0">
              Non sono presenti NFT nei contratti di questa piattaforma.
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
var Web3 = require("web3");
const ABI_1155 = require("../util/abi1155.json");
const ABI_721 = require("../util/abi721.json");
const axios = require("axios");

export default {
  name: "Mint",
  data() {
    return {
      web3: new Web3(process.env.VUE_APP_PUBLIC_RPC),
      isContractChecked: "",
      standardContract: "",
      contractAddress: "",
      account: "",
      contract: {},
      axios: axios,
      isLoading: true,
      errored: false,
      nfts: [],
      tokenIds: [],
      API_URL: process.env.VUE_APP_IPFS_ENDPOINT,
    };
  },
  mounted() {
    this.connect();
  },
  methods: {
    /**
     * @brief Get all nft transfered to a given ownerAddress and not yet
     * transfered again to someone other
     */
    /* NOTE: This implementation relies on client-side features only, thus
     * it is extremely slow and should be used only for testing/demo
     * purposes
     */
    async receivedAndOwned(contractObj) {
      // Get all NFTs transfered to the given owner
      const transferedToOwner = await contractObj.getPastEvents(
        "TransferSingle",
        {
          fromBlock: process.env.VUE_APP_FILTER_BLOCKNUMBER_START,
          // For speeding up a little bit this
          // is fixed to the very last block
          // before contract deployment.
          // See app parameters for configuring this
          // value accordingly
          toBlock: "latest",
        }
      );
      // Get all NFTs transfered by the given owner
      const transferedFromOwner = await contractObj.getPastEvents(
        "TransferSingle",
        {
          fromBlock: process.env.VUE_APP_FILTER_BLOCKNUMBER_START,
          // The same as above
          toBlock: "latest",
        }
      );
      console.log(transferedFromOwner);
      // prune from minted tokens (seems that a TransferSingle events is
      // emitted from 0x0000.000 to owner  ), then map event objects to a
      // simpler object
      let owned = await transferedToOwner
        .filter((evt) => {
          if (
            evt.returnValues.from !==
            "0x0000000000000000000000000000000000000000"
          ) {
            return true;
          }
        })
        .map((evt) => {
          // Just take the tokenID and the number of token transfered
          return {
            id: evt.returnValues.id,
            value: evt.returnValues.value,
          };
        });
      // Remove duplicate entries
      let ownedNoDuplicates = [];
      await owned.forEach((token) => {
        const foundIdx = ownedNoDuplicates.findIndex((tk) => {
          return tk.id === token.id;
        });
        console.log(foundIdx);
        if (foundIdx >= 0) {
          // We have already added this token, just increase its value
          ownedNoDuplicates[foundIdx].value += token.value;
        } else {
          // First time we see this token, let's add it
          ownedNoDuplicates.push(token);
        }
      });

      // now remove compute the "balance" (i.e. effectively owned tokens) by
      // decreasing the balance of each token by amount transfered out.
      // Then remove all tokenID having balance === 0, and take just tokenID
      // of the remaining
      const tokenIds = await ownedNoDuplicates
        .map((token) => {
          const found = transferedFromOwner.find((evt) => {
            return evt.returnValues.id === token.id;
          });
          if (found) {
            token.value -= found.returnValues.value;
          }
          return token;
        })
        .filter((token) => {
          return token.value > 0;
        })
        .map((token) => {
          return token.id;
        });

      return tokenIds;
    },
    async connect() {
      const app = this;
      try {
        const erc721Contracts =
          process.env.VUE_APP_721_CONTRACT_ADDRESSES.split(",");
        const erc1155Contracts =
          process.env.VUE_APP_1155_CONTRACT_ADDRESSES.split(",");
        if (erc1155Contracts.length > 0) {
          for (let k in erc721Contracts) {
            if (erc721Contracts[k].length > 0) {
              console.log("Fetching NFTs from 721:", erc721Contracts[k]);
              app.fetch721(erc721Contracts[k]);
            }
          }
        }
        if (erc1155Contracts.length > 0) {
          for (let k in erc1155Contracts) {
            if (erc1155Contracts[k].length > 0) {
              console.log("Fetching NFTs from 1155:", erc1155Contracts[k]);
              app.fetch1155(erc1155Contracts[k]);
            }
          }
        }
        setTimeout(function () {
          app.isLoading = false;
        }, 3000);
      } catch { }
    },
    async fetch1155(contract_address) {
      const app = this;
      try {
        let contract = await new app.web3.eth.Contract(
          ABI_1155,
          contract_address);
        let all = await app.receivedAndOwned(
          contract
        );

        for (let k in all) {
          const metadata = await contract.methods._idToMetadata(all[k]).call();
          try {
            console.log("Downloading metadata from " + metadata);
            const json = await app.axios.get(
              process.env.VUE_APP_API_URL + "/ipfs/" + metadata
            );
            json.data.tokenId = all[k];
            json.data.contract = contract_address;
            json.data.standard = 1155;
            app.isLoading = false;
            console.log("TokenId:", all[k])
            if (app.tokenIds.indexOf(json.data.tokenId) === -1) {
              app.tokenIds.push(json.data.tokenId)
              app.nfts.unshift(json.data);
              console.log("-> Metadata fetched correctly.");
            }
          } catch (e) {
            console.log("-> Can't fetch metadata from " + metadata);
          }
        }
      } catch (e) {
        console.log("ERRORED 1155", e)
        app.isLoading = false;
        app.errored = true;
      }
    },
    async fetch721(contract_address) {
      const app = this;
      try {
        let contract = await new app.web3.eth.Contract(
          ABI_721,
          contract_address);
        let supply = await contract.methods.totalSupply().call();

        for (let k = 1; k <= supply; k++) {
          const owner = await contract.methods.ownerOf(k).call();
          console.log("Owner is:", owner);
          const metadata = (
            await contract.methods.tokenURI(k).call()
          ).replace("ipfs://", "");
          try {
            console.log("Downloading metadata from " + metadata);
            const json = await app.axios.get(
              process.env.VUE_APP_API_URL + "/ipfs/" + metadata
            );
            json.data.tokenId = k;
            json.data.contract = contract_address;
            json.data.standard = 721;
            app.isLoading = false;
            if (app.tokenIds.indexOf(json.data.tokenId) === -1) {
              app.tokenIds.push(json.data.tokenId)
              app.nfts.unshift(json.data);
              console.log("-> Metadata fetched correctly.");
            }
          } catch (e) {
            console.log("-> Can't fetch metadata from " + metadata);
          }
        }
      } catch (e) {
        console.log("ERRORED 721", e.message);
        app.isLoading = false;
        app.errored = true;
      }
    },
  },
};
</script>

<style scoped>
#printLog {
  word-break: break-all;
}
</style>
